<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      title="个人信息"
      @cancel="cancelModal"
    >
      <template slot="footer">
        <a-button key="back" @click="cancelModal">
          关闭
        </a-button>
      </template>
      <a-form
        layout="inline"
        :form="form"
        :label-col="{ span: 10 }"
        :wrapper-col="{ span: 20 }"
      >
        <div>
          <div class="current-page-text-color">登录账号</div>
          <div class="se-padding-top-10px padding-block-10px">
            {{ data.username }}
          </div>
        </div>
        <a-divider class="divider-margin-block" />

        <!--    密码修改    -->
        <div class="current-page-text-color margin-top-10px">登录密码</div>
        <div class="se-padding-top-10px">
          <div v-if="isShowUpdatePassword">已设置</div>
          <div v-if="!isShowUpdatePassword">
            <a-form-item>
              <a-input-password
                v-if="!isShowUpdatePassword"
                class="input-width"
                autocomplete="new-password"
                placeholder="请输入新密码"
                v-decorator="['password', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { min: 6, message: '最少6个字符' },
                    { max: 50, message: '最多50个字符' },
                    { required: true, message: '请输入新密码' },
                    { validator: validateToNextPassword },
                  ]
                }]"
              />
            </a-form-item>

            <a-form-item>
              <a-input-password
                v-if="!isShowUpdatePassword"
                autocomplete="new-password"
                placeholder="请再次输入新密码"
                class="input-width"
                v-decorator="['confirm', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { min: 6, message: '最少6个字符' },
                    { max: 50, message: '最多50个字符' },
                    { required: true, message: '请再次输入新密码' },
                    { validator: compareToFirstPassword },
                  ]
                }]"
                @blur="handleConfirmBlur"
              />
            </a-form-item>
          </div>
        </div>
        <div
          class="current-page-text-color action-position"
        >
          <span v-if="isShowUpdatePassword" class="edit-text-position se-cursor-pointer" @click="reverseShowUpdatePasswordStatus()">编辑</span>
          <span v-if="!isShowUpdatePassword" class="cancel-text-position se-cursor-pointer" @click="reverseShowUpdatePasswordStatus()">取消</span>
          <span v-if="!isShowUpdatePassword" class="save-text-position se-cursor-pointer" @click="updateUserSingleField('password')">保存</span>
        </div>
        <a-divider class="divider-margin-block" />

        <!--    姓名修改    -->
        <div class="current-page-text-color margin-top-10px">姓名</div>
        <div class="se-padding-top-10px">
          <div v-if="isShowUpdateFullName">{{ data.full_name }}</div>

          <div v-show="!isShowUpdateFullName">
            <a-form-item>
              <a-input
                v-show="!isShowUpdateFullName"
                :disabled="isShowUpdateFullName"
                class="input-width"
                v-decorator="['full_name', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { required: true, message: '请输入姓名' },
                    { max: 50, message: '最多50个字符' },
                  ]
                }]"
              />
            </a-form-item>
          </div>
          <div class="current-page-text-color action-position">
            <span v-if="isShowUpdateFullName" class="edit-text-position se-cursor-pointer" @click="reverseShowUpdateFullNameStatus()">编辑</span>
            <span v-if="!isShowUpdateFullName" class="cancel-text-position se-cursor-pointer" @click="reverseShowUpdateFullNameStatus()">取消</span>
            <span v-if="!isShowUpdateFullName" class="save-text-position se-cursor-pointer" @click="updateUserSingleField('full_name')">保存</span>
          </div>
        </div>
        <a-divider class="divider-margin-block" />

        <!--    手机号修改    -->
        <div class="current-page-text-color margin-top-10px">手机号</div>
        <div class="se-padding-top-10px">
          <div v-if="isShowUpdatePhoneNumber">{{ data.phone_number }}</div>
          <div v-show="!isShowUpdatePhoneNumber">
            <a-form-item>
              <a-input
                v-show="!isShowUpdatePhoneNumber"
                :disabled="isShowUpdatePhoneNumber"
                class="input-width"
                v-decorator="['phone_number', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { required: true, message: '请输入手机号' },
                    { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确' },
                  ]
                }]"
              />
            </a-form-item>
          </div>

          <div class="current-page-text-color action-position">
            <span
              v-if="isShowUpdatePhoneNumber"
              class="edit-text-position se-cursor-pointer"
              @click="reverseShowUpdatePhoneNumberStatus()"
            >编辑</span>
            <span
              v-if="!isShowUpdatePhoneNumber"
              class="cancel-text-position se-cursor-pointer"
              @click="reverseShowUpdatePhoneNumberStatus()"
            >取消</span>
            <span v-if="!isShowUpdatePhoneNumber" class="save-text-position se-cursor-pointer" @click="updateUserSingleField('phone_number')">保存</span>
          </div>
        </div>
        <a-divider class="divider-margin-block" />

        <!--    联系邮箱修改    -->
        <div class="current-page-text-color margin-top-10px">联系邮箱</div>
        <div class="se-padding-top-10px">
          <div v-if="isShowUpdateEmail">{{ data.email }}</div>
          <div v-show="!isShowUpdateEmail">
            <a-form-item>
              <a-input
                v-show="!isShowUpdateEmail"
                :disabled="isShowUpdateEmail"
                class="input-width"
                v-decorator="['email', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { pattern: /^[a-z0-9._%-]+@([a-z0-9-]+\.)+[a-z]{2,4}$/, message: '邮箱格式不正确' },
                  ]
                }]"
              />
            </a-form-item>
          </div>

          <div class="current-page-text-color action-position">
            <span v-if="isShowUpdateEmail" class="edit-text-position se-cursor-pointer" @click="reverseShowUpdateEmailStatus()">编辑</span>
            <span v-if="!isShowUpdateEmail" class="cancel-text-position se-cursor-pointer" @click="reverseShowUpdateEmailStatus()">取消</span>
            <span v-if="!isShowUpdateEmail" class="save-text-position se-cursor-pointer" @click="updateUserSingleField('email')">保存</span>
          </div>
        </div>
        <a-divider class="divider-margin-block" />

        <div>
          <div class="current-page-text-color margin-top-10px">角色</div>
          <div class="se-padding-top-10px">
            {{ data.role }}
          </div>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { findUserBasicInfo, updateUserSingleField } from '@/api/user'

export default {
  name: 'PersonalInformation',
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  created() {
    this.fetchData()
  },
  data() {
    return {
      confirmDirty: false,
      form: this.$form.createForm(this, { name: 'user' }),
      submitting: false,
      isShowUpdateUserName: true,
      isShowUpdateFullName: true,
      isShowUpdatePhoneNumber: true,
      isShowUpdatePassword: true,
      isShowUpdateEmail: true,
      data: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    // fetchData 获取数据
    fetchData() {
      findUserBasicInfo().then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
      })
    },

    // reverseShowUpdatePasswordStatus 密码修改
    reverseShowUpdatePasswordStatus() {
      this.isShowUpdatePassword = !this.isShowUpdatePassword
    },

    // reverseShowUpdateFullNameStatus 姓名修改
    reverseShowUpdateFullNameStatus() {
      this.form.setFieldsValue({
        full_name: this.data.full_name
      })
      this.isShowUpdateFullName = !this.isShowUpdateFullName
    },

    // reverseShowUpdatePhoneNumberStatus 手机号修改
    reverseShowUpdatePhoneNumberStatus() {
      this.form.setFieldsValue({
        phone_number: this.data.phone_number
      })
      this.isShowUpdatePhoneNumber = !this.isShowUpdatePhoneNumber
    },

    // reverseShowUpdateEmailStatus 邮箱修改
    reverseShowUpdateEmailStatus() {
      this.form.setFieldsValue({
        email: this.data.email
      })
      this.isShowUpdateEmail = !this.isShowUpdateEmail
    },

    // updateUserSingleField 提交更新
    updateUserSingleField(field) {
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      var needValidateFields = [field]
      if (field === 'password') {
        needValidateFields = ['password', 'confirm']
      }
      this.form.validateFields(needValidateFields, (err, values) => {
        if (!err) {
          var value = ''
          switch (field) {
            case 'password':
              value = values.password
              this.isShowUpdatePassword = true
              break
            case 'full_name':
              this.isShowUpdateFullName = true
              value = values.full_name
              break
            case 'phone_number':
              this.isShowUpdatePhoneNumber = true
              value = values.phone_number
              break
            case 'email':
              this.isShowUpdateEmail = true
              value = values.email
              break
          }

          this.submitting = true
          updateUserSingleField({
            field: field,
            value: value,
            id: this.$store.state.user.id
          }).then((res) => {
            if (res.code === 0) {
              this.fetchData()
              // 获取最新信息
              this.$store.dispatch('FindUserInfo')
            }
            this.submitting = false
          })
        }
      })
    },

    // compareToFirstPassword 校验密码相同
    compareToFirstPassword(rule, value, callback) {
      const form = this.form
      if (value && value !== form.getFieldValue('password')) {
        callback(new Error('请输入相同密码'))
      } else {
        callback()
      }
    },

    // validateToNextPassword 校验密码相同
    validateToNextPassword(rule, value, callback) {
      const form = this.form
      if (value && this.confirmDirty) {
        form.validateFields(['confirm'], { force: true })
      }
      callback()
    },

    // handleConfirmBlur 回调
    handleConfirmBlur(e) {
      const value = e.target.value
      this.confirmDirty = this.confirmDirty || !!value
    },

    // cancelModal 模态框关闭
    cancelModal() {
      this.isShow = false
    }
  }
}
</script>

<style lang="less" scoped>

  /* current-page-text-color 页面文字颜色 */
  .current-page-text-color {
    color: @primary-color;
  }

  /* current-page-text-color span 页面span颜色 */
  .current-page-text-color span {
    color: @primary-color;
  }

  /*  input-width 输入框的长度 */
  .input-width {
    width: 255px;
    z-index: 10
  }

  /*  edit-text-position  编辑文字位置 */
  .edit-text-position {
    margin-left: 93%;
  }

  /*  cancel-text-position  取消文字位置 */
  .cancel-text-position {
    padding-left: 84%;
  }

  /*  save-text-position  保存文字位置  */
  .save-text-position {
    padding-left: 15px;
  }

  /*  divider-margin-block  下划线边缘块宽度  */
  .divider-margin-block {
    margin-block: 3px;
  }

  /*  action-position 编辑修改保存文字位置 */
  .action-position {
    bottom: 22px;
    position: relative;
  }

  /*  margin-top-10px 头边缘宽度 */
  .margin-top-10px {
    margin-top: 10px;
  }

  /*  padding-block-10px  填充宽度  */
  .padding-block-10px {
    padding-block: 10px;
  }
</style>
