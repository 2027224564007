import request from '@/utils/request'

// 查询用户列表数据
export function findUsersByAdmin(params) {
  return request({
    url: `/admin/users`,
    method: 'get',
    params: params
  })
}

// 创建用户
export function createUserByAdmin(data) {
  return request({
    url: `/admin/users`,
    method: 'post',
    data
  })
}

// 查询编辑用户表单数据
export function findUserFormByAdmin(id) {
  return request({
    url: `/admin/users/${id}/form`,
    method: 'get'
  })
}

// 更新用户
export function updateUserByAdmin(id, data) {
  return request({
    url: `/admin/users/${id}`,
    method: 'put',
    data
  })
}

// 更新用户有效性
export function updateUserEffectiveByAdmin(id, data) {
  return request({
    url: `/admin/users/${id}/effective`,
    method: 'patch',
    data
  })
}

// 删除用户
export function destroyUserByAdmin(id) {
  return request({
    url: `/admin/users/${id}`,
    method: 'delete'
  })
}

// findUserBasicInfo 通过id查找用户
export function findUserBasicInfo() {
  return request({
    url: `/users/basic_info`,
    method: 'get'
  })
}

// updateUserSingleField 修改用户单个字段
export function updateUserSingleField(data) {
  return request({
    url: `/users/single_field`,
    method: 'put',
    data
  })
}
